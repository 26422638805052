<template>
  <div id="app">
    <Navigation />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';


export default {
  name: "app",
  components: {
    Navigation,
    Footer,
  },
}
</script>

<style lang="scss">
@import "./static/css/no-parallax.scss";

@font-face {
  font-family: "Utopia";
  src: url("assets/fonts/UtopiaStd-Regular.otf");
}

html {
	scroll-behavior: smooth;
}

.grecaptcha-badge { visibility: hidden; }

#app {
  font-family: "Utopia", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  font-weight: bolder;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

</style>
