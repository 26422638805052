<template>
    <div id="footerSite" class="p-4">
        <div class="container">
            <div>
                <img style="width: 250px;" src="@/assets/img/logo_showroomiris.png" alt="Logo Showroom d'Iris" />
            </div>
            <p class="mt-4">Liens</p>
            <div class="row liens mb-5">
                <div class="col text-right">
                    <a href="/#mariage">Mariages</a><br />
                    <a href="/#evenements">Évènements</a><br />
                    <a href="/#portrait">Portraits</a><br />
                </div>
                <div class="col text-left">
                    <a href="/#quisuisje">Qui suis-je</a><br />
                    <a href="/#contact">Me contacter</a><br />
                    <a href="http://www.showroomdiris.fr/blog">Mon Blog</a>
                </div>
            </div>
            <div>
                Showroom d'Iris - <a href="/mentionslegales">Mentions légales</a>
            </div>
            <div>
                Copyright © Showroomdiris - Tous droits réservés.
            </div>
            <div>
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "footerSite"
}
</script>

<style scoped>
#footerSite {
    position: relative;
    width: 100%;
    background-color: #FFF8F0;
    box-shadow: inset 0 0 15px #2D2A32;
    z-index: 10;
}

.liens a {
    font-size: 1.2em;
    color: grey;
    text-decoration: underline;
}
</style>