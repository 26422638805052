<template>
<div id="mentionslegales" class=" container">

  <h1 align=center style='text-align:center'>Mentions légales</h1>


  <p class=MsoNormal align=center style='text-align:center'><i>En vigueur au
  10/01/2021</i></p>

  <p class=MsoNormal><br /><br />
    Dénomination sociale : Showroom d'Iris<br />
    Site internet : www.showroomdiris.fr<br />
    Représenté par : Mme Iris Caux, Photographe<br />
    Adresse du siège social : 1,Rue Dolain, 62100 Calais<br />
    N° de SIRET : 83195311200016<br /><br />
  </p>

  <p class=MsoNormal>Conformément aux dispositions des Articles 6-III et 19 de la
  Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
  dite L.C.E.N., il est porté à la connaissance des Utilisateurs du site <i>showroomdiris.fr
  </i>les présentes mentions légales.</p>

  <p class=MsoNormal>La connexion et la navigation sur le site showroomdiris.fr
  par l’Utilisateur implique acceptation intégrale et sans réserve des
  présentes mentions légales.</p>

  <p class=MsoNormal>Ces dernières sont accessibles sur le site à la rubrique «
  Mentions légales ».</p>

  <p class=MsoNormal><br>
  <br>
  </p>

  <h1>ARTICLE 1 : L’éditeur</h1>

  <p class=MsoNormal><br>
  </p>

  <p class=MsoNormal>L’édition et la direction de la publication du site
  showroomdiris.fr est assurée par Alex Caux, domiciliée 1 Rue Dolain 62100
  Calais, dont le numéro de téléphone est 06 01 79 43 29, et l'adresse e-mail
  caux.alex@outlook.com.</p>

  <p class=MsoNormal><br>
  <br>
  </p>

  <h1>ARTICLE 2 : L’hébergeur</h1>

  <p class=MsoNormal><br>
  </p>

  <p class=MsoNormal>L'hébergeur du site showroomdiris.fr est la Société OVH,
  dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix - France ,
  avec le numéro de téléphone : 09 72 10 10 07.</p>

  <p class=MsoNormal><br>
  <br>
  </p>

  <h1>ARTICLE 3 : Accès au site</h1>

  <p class=MsoNormal><br>
  </p>

  <p class=MsoNormal>Le site est accessible par tout endroit, 7j/7, 24h/24 sauf
  cas de force majeure, interruption programmée ou non et pouvant découlant d’une
  nécessité de maintenance.</p>

  <p class=MsoNormal>En cas de modification, interruption ou suspension des
  services le site <i>showroomdiris.fr </i>ne saurait être tenu responsable.</p>

  <p class=MsoNormal><br>
  <br>
  </p>

  <h1>ARTICLE 4 : Collecte des données</h1>

  <p class=MsoNormal><br>
  </p>

  <p class=MsoNormal><span style='font-size:10.0pt;line-height:107%'>Le site est
  exempté de déclaration à la Commission Nationale Informatique et Libertés
  (CNIL) dans la mesure où il ne collecte aucune donnée concernant les
  utilisateurs.</span></p>

  <p class=MsoNormal><br>
  <br>
  </p>

  <h1>ARTICLE 5 : Cookies</h1>

  <p class=MsoNormal><br>
  </p>

  <p class=MsoNormal>L’Utilisateur est informé que lors de ses visites sur le
  site, un cookie peut s’installer automatiquement sur son logiciel de
  navigation.</p>

  <p class=MsoNormal>En naviguant sur le site, il les accepte.</p>

  <p class=MsoNormal>Un cookie est un élément qui ne permet pas d’identifier
  l’Utilisateur mais sert à enregistrer des informations relatives à la
  navigation de celui-ci sur le site Internet. L’Utilisateur pourra désactiver ce
  cookie par l’intermédiaire des paramètres figurant au sein de son logiciel de
  navigation.</p>

  <p class=MsoNormal><br>
  <br>
  </p>

  <h1>ARTICLE 6 : Propriété intellectuelle</h1>

  <p class=MsoNormal><br>
  </p>

  <p class=MsoNormal>Toute utilisation, reproduction, diffusion,
  commercialisation, modification de toute ou partie du site <i>showroomdiris.fr</i>,
  sans autorisation de l’Editeur est prohibée et pourra entraînée des actions et
  poursuites judiciaires telles que notamment prévues par le Code de la propriété
  intellectuelle et le Code civil.</p>

  <p class=MsoNormal><br>
  <br><br /><br />
  </p>

</div>
</template>

<script>
export default {
  mounted () {
    document.getElementsByTagName("nav")[0].style.filter = "invert()";
  },
  name: "mentionslegales",
};
</script>

<style scoped>
#mentionslegales {
  padding-top: 25vh;
}

.MsoNormal {
  text-align: left;
}


 p.MsoNormal, li.MsoNormal, div.MsoNormal	{
  margin-top:3.0pt;
	margin-right:0in;
	margin-bottom:0in;
	margin-left:0in;
	text-align:justify;
	text-justify:inter-ideograph;
	line-height:107%;
	font-size:10.5pt;
	font-family:"Arial",sans-serif;
}
h1 {
  margin-top:.25in;
	margin-right:0in;
	margin-bottom:6.0pt;
	margin-left:0in;
	line-height:107%;
	page-break-after:avoid;
	font-size:14.0pt;
	font-family:"Calibri",sans-serif;
	color:#051259;
}
.MsoPapDefault {
  margin-bottom:8.0pt;
	line-height:107%;
}
</style>
