<template>
  <b-navbar toggleable="sm">

      <b-navbar-nav class="nav-center">
        <b-nav-item href="/" class="transparentLink"><img id="logo-img" src="@/assets/img/logo_showroomiris.png" alt="iris - photographe" /></b-nav-item>
        <!-- src="@/assets/img/logo_showroomiris.png" -->
      </b-navbar-nav>


    <b-navbar-toggle target="navbar-toggle-collapse">
      <template #default="{ expanded }">
        <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
        <b-icon v-else icon="chevron-bar-down"></b-icon>
      </template>
    </b-navbar-toggle>

    <b-collapse id="navbar-toggle-collapse" is-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item href="https://showroomdiris.fr/blog/" target="_blank">Blog</b-nav-item>
      </b-navbar-nav>

    </b-collapse>

  </b-navbar>
</template>

<script>

export default {
    name: "navigation",
    components:{
    }
}
</script>

<style>
nav {
  background: transparent;
  z-index: 10000;
  position: absolute !important;
  width: 100%;
}
nav a {
  color: white !important;
  font-size: 25pt;
  margin: 0;
}

.navbar-toggler {
  overflow-anchor: none;
  position: absolute;
  right: 10px;
  top: 10px;
}

.nav-center {
  position: relative;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
}

.nav-center .transparentLink {
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

#navbar-toggle-collapse {
 position: relative;
 align-self: baseline;
}

#logo-img {
  max-width: 100%;
  min-height: 100px;
  max-height: 22vh;
  filter: opacity(85%);
}
</style>