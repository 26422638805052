<template>
    <div id="dernierBlog">
        <h1>Dernièrement sur le blog</h1>
        <div class="row">
            <div class="col m-md-3 p-md-2 m-1 p-1 cardArticle" v-for="article in articles" :key="article.link">
                <a :href="article.link" target="_blank">
                    <v-lazy-image :src="article.better_featured_image.source_url" :alt="'Image de l article ' + article.title.rendered" />
                    <div class="articleTitre">
                        <h3 v-html="article.title.rendered"></h3>
                    </div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
const axios = require("axios");
import VLazyImage from "v-lazy-image";

export default {
    name: "dernierBlog",
	components: {
		VLazyImage
	},
    data () {
        return {
            articles: []
        }
    },
    created () {
        axios.get("https://showroomdiris.fr/blog/wp-json/wp/v2/posts?per_page=3&order_by=date&order=desc")
            .then((response) => {
                this.articles = response.data;
                /* eslint-disable
                console.log(response.data);
                console.log(response.data[0].title.rendered);
                console.log(decodeURIComponent(response.data[0].title.rendered)); */
            });
    }
}
</script>

<style>
#dernierBlog h1 {
    font-size: 11vh;
}
#dernierBlog .row {
    margin-top: 15vh;
    margin-bottom: 10vh;
}
.cardArticle {
    background-color: white;
    height: 50vh;
    border-radius: 5px;
    position: relative;
    -webkit-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 32px 0px rgba(0,0,0,0.75);
}
.cardArticle a {
    height: 100%;
    width: 100%;
    color: black;
}
.cardArticle a:hover {
    text-decoration: none;
    color: grey;
}
.cardArticle img {
    object-fit: cover;
    width: 100%;
    height: 70%;
}
.cardArticle div.articleTitre {
    height: 30%;
    position: relative;
    text-align: center;
}
.cardArticle div.articleTitre h3 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    font-size: 1.3vw;
}
</style>