import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import MentionsLegales from './views/MentionsLegales.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/mentionslegales',
      name: 'mentionslegales',
      component: MentionsLegales,
      beforeEnter: (to, from, next) => {
        document.title = "Showroom d'Iris - Mentions légales"
        if (!document.head.querySelector('meta[name=description]'))
          AddMetaTag('description')

        document.head.querySelector('meta[name=description]').content = "Mentions légales de Showroom d'Iris - photographe dans le Pas de Calais";
        return next()
      }
    }
  ]
})


function AddMetaTag(name){
  var link = document.createElement('meta')
  link.setAttribute('name', name);
  var parent = document.getElementsByTagName('head')[0];
  var child = parent.getElementsByTagName('title')[0];
  insertAfter(link, child);
}

function insertAfter(newNode, referenceNode) {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}