<template>
  <div class="home" :class="{ 'no-parallax': !isParallaxStarted }" style="width: 100%">
    <Offre />

    <div class="reseaux">
      <a
        v-for="reseau in reseaux"
        :key="reseau.nom"
        :href="reseau.url"
        :alt="'Lien vers la page ' + reseau.nom"
        target="_blank"
      >
        <div
          style="text-align: right"
          :style="'background-color: ' + reseau.bgColor + '; width: ' + reseau.width"
          class="reseau-slide"
          :class="reseau.nom"
        >
          <div>{{ reseau.text }}</div>
          <img :src="getImgUrl(reseau.icon)" />
        </div>
      </a>
    </div>

    <div id="home" class="divAccueil" style="z-index: 10">
      <v-lazy-image
        v-parallax.absY="-0.6"
        :src="
          getImgUrl(
            isParallaxStarted
              ? 'accueil-entete-paysage.jpg'
              : 'accueil-entete-portrait.jpg'
          )
        "
        :src-placeholder="getImgUrl('accueil-entete-placeholder.jpg')"
      />
      <a
        v-parallax.absY="-0.6"
        href="#mariage"
        class="scroll-to-down"
        style="bottom: 18vh"
      >
        <img src="@/assets/img/fleche-bas.png" />
      </a>
    </div>

    <div id="mariage" class="divAccueil" style="z-index: 5; height: 105vh">
      <div
        v-parallax.absY="-0.45"
        class="parallax-text"
        :class="weddingGalery.show ? 'bg-portrait' : ''"
      >
        <h1>Mariage</h1>

        <p v-if="!weddingGalery.show || !isParallaxStarted">
          Je vous accompagne, en toute discrétion, capturant les émotions et les instants
          faisant de ce jour l'un des plus beaux de votre vie...
        </p>
        <div v-if="weddingGalery.show" class="divGalery">
          <img
            v-for="item in weddingGalery.items"
            :key="item.src"
            :src="getImgUrl(thumb(item.src))"
            :alt="item.alt"
            @click="selectImgGalery(weddingGalery, item)"
          />
        </div>

        <div class="button_cont" align="center">
          <a
            class="goto-gallery to-mariage"
            href="#mariage"
            @click="weddingGalery.show = !weddingGalery.show"
          >
            {{ weddingGalery.show ? "X" : "Galerie" }}
          </a>
          <a class="goto-gallery to-contact" href="#contact" rel="nofollow noopener">
            Me contacter</a
          >
        </div>
      </div>
      <v-lazy-image
        v-parallax.absY="0.3"
        :src="getImgUrl(weddingGalery.backImage)"
        alt="photo de couple lors d\'un mariage"
        :class="[weddingGalery.show ? 'invisible' : 'visible']"
        :src-placeholder="getImgUrl(placeholder(weddingGalery.backImage))"
      />
      <div class="gallery_container gallery_portrait" v-if="weddingGalery.show">
        <transition name="fade">
          <v-lazy-image
            v-if="!weddingGalery.transition"
            :src="
              getImgUrl(
                weddingGalery.selectedImage
                  ? weddingGalery.selectedImage.src
                  : weddingGalery.backImage
              )
            "
            :alt="
              weddingGalery.selectedImage
                ? weddingGalery.selectedImage.alt
                : 'photo de couple lors d\'un mariage'
            "
            :class="
              weddingGalery.selectedImage && weddingGalery.selectedImage.isPortrait
                ? 'bg-portrait'
                : ''
            "
            :src-placeholder="
              getImgUrl(
                placeholder(
                  weddingGalery.selectedImage
                    ? weddingGalery.selectedImage.src
                    : weddingGalery.backImage
                )
              )
            "
          />
        </transition>
      </div>
      <a
        v-parallax.absY="0.3"
        href="#evenements"
        class="scroll-to-down"
        style="bottom: -24vh"
      >
        <img src="@/assets/img/fleche-bas.png" />
      </a>
    </div>

    <div id="evenements" class="divAccueil" style="z-index: 10">
      <div
        :class="{ 'event-portrait': eventsGalery.show }"
        v-parallax.absY="-0.2"
        style="width: 100%; top: 0"
        class="parallax-text"
        id="text-evenements"
      >
        <!-- color: #000000bd; -->
        <h1>Évènement</h1>
        <p v-if="!eventsGalery.show || !isParallaxStarted">
          Enterrements de vie de jeune fille, après-midi entre copines, grossesse, tant
          d'évènements marquants à immortaliser
        </p>
        <div align="center">
          <div v-if="eventsGalery.show" class="divGalery d-inline-block">
            <a v-for="item in eventsGalery.items" :key="item.src" href="#evenements">
              <img
                :src="getImgUrl(thumb(item.src))"
                :alt="item.alt"
                @click="selectImgGalery(eventsGalery, item)"
                :src-placeholder="getImgUrl(placeholder(item.src))"
              />
            </a>
          </div>

          <div class="button_cont align-middle">
            <a
              class="goto-gallery to-evenement"
              href="#evenements"
              @click="eventsGalery.show = !eventsGalery.show"
            >
              {{ eventsGalery.show ? "X" : "Galerie" }}
            </a>
            <a class="goto-gallery to-contact" href="#contact" rel="nofollow noopener">
              Me contacter</a
            >
          </div>
        </div>
      </div>

      <div class="animationContainer" style="width: 100%; background-color: white">
        <v-lazy-image
          v-if="!eventsGalery.transition"
          :src="getImgUrl(eventsGalery.backImage)"
          alt="photo à la plage lors d\'un enterrement de vie de jeune fille"
          :class="[eventsGalery.show ? 'invisible' : 'visible']"
          style="height: 110vh"
          :src-placeholder="getImgUrl(placeholder(eventsGalery.backImage))"
        />

        <div class="gallery_container" v-if="eventsGalery.show">
          <transition name="fade">
            <v-lazy-image
              v-if="!eventsGalery.transition"
              :src="
                getImgUrl(
                  eventsGalery.selectedImage
                    ? eventsGalery.selectedImage.src
                    : eventsGalery.backImage
                )
              "
              :alt="
                eventsGalery.selectedImage
                  ? eventsGalery.selectedImage.alt
                  : 'photo à la plage lors d\'un enterrement de vie de jeune fille'
              "
              :class="
                eventsGalery.selectedImage && eventsGalery.selectedImage.isPortrait
                  ? 'bg-portrait bg-portrait-event'
                  : ''
              "
              :src-placeholder="
                getImgUrl(
                  placeholder(
                    eventsGalery.selectedImage
                      ? eventsGalery.selectedImage.src
                      : eventsGalery.backImage
                  )
                )
              "
            />
          </transition>
        </div>
      </div>

      <a v-parallax.absY="0" href="#portrait" class="scroll-to-down" style="bottom: 17vh">
        <img src="@/assets/img/fleche-bas.png" />
      </a>
    </div>

    <div
      id="portrait"
      class="divAccueil"
      style="z-index: 5; background-color: white; height: 100vh"
    >
      <div
        v-parallax.absY="-0.45"
        class="parallax-text"
        :class="portraitGalery.show ? 'bg-portrait' : ''"
        style="top: 17%; right: 100px; width: 60vh; padding: 0"
      >
        <h1>Portrait</h1>
        <p v-if="!portraitGalery.show || !isParallaxStarted">
          Que ce soit en extérieur, dans mon studio à Calais ou même sur fond vert, je
          ferai la photo qui vous représente le mieux, ou qui vous incruste dans le décors
          de vos rêves
        </p>

        <div v-if="portraitGalery.show" class="divGalery">
          <img
            v-for="item in portraitGalery.items"
            :key="item.src"
            :src="getImgUrl(thumb(item.src))"
            :alt="item.alt"
            @click="selectImgGalery(portraitGalery, item)"
            :src-placeholder="getImgUrl(placeholder(item.src))"
          />
        </div>

        <div class="button_cont" align="center">
          <a
            class="goto-gallery to-portrait"
            href="#portrait"
            @click="portraitGalery.show = !portraitGalery.show"
          >
            {{ portraitGalery.show ? "X" : "Galerie" }}
          </a>
          <a class="goto-gallery to-contact" href="#contact"> Me contacter</a>
        </div>
      </div>

      <v-lazy-image
        v-parallax.absY="0.3"
        :src="getImgUrl(portraitGalery.backImage)"
        alt="photo portrait d\'une modèle en extérieur devant un mur de brique"
        :class="[portraitGalery.show ? 'invisible' : 'visible']"
        :src-placeholder="getImgUrl(placeholder(portraitGalery.backImage))"
      />
      <div class="gallery_container gallery_portrait" v-if="portraitGalery.show">
        <transition name="fade">
          <v-lazy-image
            v-if="!portraitGalery.transition"
            :src="
              getImgUrl(
                portraitGalery.selectedImage
                  ? portraitGalery.selectedImage.src
                  : portraitGalery.backImage
              )
            "
            :alt="
              portraitGalery.selectedImage
                ? portraitGalery.selectedImage.alt
                : 'photo portrait d\'une modèle en extérieur devant un mur de brique'
            "
            :class="
              portraitGalery.selectedImage && portraitGalery.selectedImage.isPortrait
                ? 'bg-portrait'
                : ''
            "
            :src-placeholder="
              getImgUrl(
                placeholder(
                  portraitGalery.selectedImage
                    ? portraitGalery.selectedImage.src
                    : portraitGalery.backImage
                )
              )
            "
          />
        </transition>
      </div>

      <a
        v-parallax.absY="0.3"
        href="#quisuisje"
        class="scroll-to-down"
        style="bottom: -27vh"
      >
        <img src="@/assets/img/fleche-bas.png" />
      </a>
    </div>

    <div
      id="quisuisje"
      class="divAccueil"
      style="z-index: 6; margin-top: 30vh; height: 108vh; background-color: white"
      ref="myEl"
    >
      <div
        class="parallax-text"
        style="
          top: 1vh;
          right: 10%;
          color: black;
          font-size: 1.3vw;
          width: 65vh;
          padding: 0 5vh;
        "
      >
        <h1 style="font-size: 4em" class="mb-5 mt-5">Qui suis-je ?</h1>
        <p>
          Je m’appelle Iris, je suis photographe professionnelle à Calais depuis fin 2017.
          <br />J’aime énormément mon métier. Les moments que je préfère le plus sont ceux
          que je passe avec vous lors de la séance photo.
          <br />
          <br />Mes photographies sont naturelles, spontanées, et je trouve important que
          les personnes que j’accompagne vivent la séance et partagent un moment complice
          entre elles sans prêter attention à l’objectif.
          <br />
          <br />Je retouche mes photos de manière à ne pas détériorer les teintes et la
          qualité de celles-ci.
          <br />
          <br />Je me déplace dans le Pas-de-Calais ainsi que dans le Nord. <br />En
          attendant notre rencontre, portez-vous bien.
          <br />
          <br />Iris
        </p>
        <div class="button_cont" align="center">
          <a class="goto-gallery to-contact" href="#contact" rel="nofollow noopener">
            Me contacter</a
          >
        </div>
      </div>
      <v-lazy-image
        id="photo-iris"
        :src="getImgUrl('portrait-iris.jpg')"
        alt="photo portrait d'Iris photographe"
        :src-placeholder="getImgUrl(placeholder('portrait-iris.jpg'))"
      />
    </div>

    <!-- Formulaire de contact -->
    <div id="contact" class="divAccueil">
      <div
        class="parallax-text"
        style="position: relative; color: white; width: 100%; top: 1%"
      >
        <b-container>
          <Contact />
        </b-container>
      </div>
    </div>

    <div
      class="divAccueil"
      id="blog"
      style="
        background-color: #6b0f1a;
        z-index: 9;
        height: auto;
        padding: 5vh 0;
        box-shadow: 0 0 15px 7px #2d2a32b3;
      "
    >
      <div
        class="parallax-text"
        style="position: relative; color: white; width: 100%; top: 1%"
      >
        <b-container>
          <DernierBlog />
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
import Contact from "@/components/Contact.vue";
import DernierBlog from "@/components/DernierementBlog.vue";
import Offre from "@/components/Offre.vue";

import VLazyImage from "v-lazy-image";

var galeryStruct = {
  show: false,
  items: [],
  backImage: null,
  selectedImage: null,
  transition: false,
};

export default {
  name: "home",
  components: {
    Contact,
    DernierBlog,
    VLazyImage,
    Offre,
  },
  data() {
    return {
      weddingGalery: { ...galeryStruct },
      eventsGalery: { ...galeryStruct },
      portraitGalery: { ...galeryStruct },
      reseaux: [
        {
          nom: "facebook",
          url: "https://wwww.facebook.com/showroomdiris",
          text: "facebook.com/showroomdiris",
          bgColor: "rgba(63, 81, 181)",
          width: "315px",
          icon: "icons/facebook.png",
        },
        {
          nom: "instagram",
          url: "https://www.instagram.com/showroomdiris/?hl=fr",
          text: "instagram.com/showroomdiris",
          bgColor: "rgba(254, 84, 64, 0.8)",
          width: "315px",
          icon: "icons/instagram.png",
        },
        {
          nom: "youtube",
          url: "https://www.youtube.com/channel/UCJLX4baunRXeP5df56x9zaw",
          text: "showroomdiris",
          bgColor: "rgba(255, 61, 0, 0.6)",
          width: "210px",
          icon: "icons/youtube.png",
        },
        {
          nom: "telephone",
          url: "tel:0768338113",
          text: "07 68 33 81 13",
          bgColor: "rgb(208, 251, 217)",
          width: "190px",
          icon: "icons/telephone.png",
        },
      ],
      lastView: null,
      isParallaxStarted: false,
    };
  },
  watch: {
    "weddingGalery.selectedImage": function () {
      this.weddingGalery.transition = true;
      setTimeout(() => (this.weddingGalery.transition = null), 200);
      this.isPortrait(this.weddingGalery.selectedImage);
    },
    "eventsGalery.selectedImage": function () {
      this.eventsGalery.transition = true;
      setTimeout(() => (this.eventsGalery.transition = null), 200);
      this.isPortrait(this.eventsGalery.selectedImage);
    },
    "portraitGalery.selectedImage": function () {
      this.portraitGalery.transition = true;
      setTimeout(() => (this.portraitGalery.transition = null), 200);
      this.isPortrait(this.portraitGalery.selectedImage);
    },
  },
  created() {
    this.loadGalerys();

    this.isParallaxStarted = this.isParallaxActivated();

    if (!this.isParallaxStarted) {
      this.weddingGalery.show = true;
      this.eventsGalery.show = true;
      this.portraitGalery.show = true;
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.reloadVueOptions);
      window.addEventListener("resize", this.reloadVueOptions);
    });
  },
  methods: {
    thumb(src) {
      return this.addSuffix(src, "-thumbnail");
    },
    placeholder(src) {
      return this.addSuffix(src, "-placeholder");
    },
    addSuffix(src, suffix) {
      let arrstr = src.split(".");
      let placeholder = arrstr[0];
      for (let i = 1; i < arrstr.length - 1; i++) {
        placeholder += arrstr[i];
      }
      return placeholder + suffix + "." + arrstr[arrstr.length - 1];
    },
    //eslint-disable-next-line no-unused-vars
    reloadVueOptions(event) {
      if (this.isParallaxStarted != this.isParallaxActivated()) {
        location.reload();
        setTimeout("location.reload();", 10);
      }
    },
    selectImgGalery(galery, item) {
      let oldImg = galery.selectedImage ? { ...galery.selectedImage } : null;
      galery.selectedImage = { ...item };
      let index = galery.items.findIndex((x) => x.src == item.src);
      galery.items[index] = oldImg || { src: galery.backImage, portrait: false };
    },
    getImgUrl(pic) {
      return require("@/assets/img/" + pic);
    },
    loadGalerys() {
      this.weddingGalery.backImage = "accueil-mariage.jpg";
      this.eventsGalery.backImage = "accueil-evenements.jpg";
      this.portraitGalery.backImage = "accueil-portrait.jpg";

      this.weddingGalery.items = [];
      for (let i = 1; i <= 3; i++)
        this.weddingGalery.items.push({ src: `mariage/mariage-${i}.jpg` });

      this.eventsGalery.items = [];
      for (let i = 1; i <= 3; i++)
        this.eventsGalery.items.push({ src: `evenement/evenements-${i}.jpg` });

      this.portraitGalery.items = [];
      for (let i = 1; i <= 3; i++)
        this.portraitGalery.items.push({ src: `portrait/portrait-${i}.jpg` });
    },
    addGaleryImg(galery, image, imgSrc) {
      image.onload = () => {
        galery.push({
          src: imgSrc,
          portrait: image.height >= image.width,
        });
      };
    },
    isPortrait(img) {
      let image = new Image();
      image.src = this.getImgUrl(img.src);
      image.onload = () => {
        img.isPortrait = image.height >= image.width;
      };
    },
    isParallaxActivated() {
      const vw = Math.min(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const vh = Math.min(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );

      let minSide = vw > vh ? vh : vw;
      let maxSide = vw > vh ? vw : vh;

      return maxSide >= 992 && maxSide >= minSide * (30 / 17);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-lazy-image {
  filter: blur(5px);
  transition: filter 1.6s;
  will-change: filter;
}
.v-lazy-image-loaded {
  filter: blur(0);
}

.gallery_container {
  position: relative;
  width: 65%;
  height: 85vh;
  float: right;
  margin-top: 6vh;
  margin-right: 25px;
  object-fit: contain;

  &.gallery_portrait {
    float: left;
    margin-right: 0;
    margin-left: 85px;
  }
}

::-webkit-scrollbar {
  display: none;
}

.reseaux {
  position: fixed;
  left: 0;
  top: calc(40% - 60px);
  z-index: 1000;

  a > div {
    height: 58px;
    border-radius: 20px;
    margin-bottom: 10px;
    position: relative;
    color: white;
    padding-left: 30px;

    div {
      display: inline-block;
    }
    img {
      display: inline-block;
      height: 100%;
    }
  }

  .reseau-slide {
    transition: 1s;
    margin-left: -260px;

    &.telephone {
      color: black;
      margin-left: -135px;
    }

    &.youtube {
      margin-left: -152px;
    }

    &:hover {
      margin-left: -20px;
    }
  }
}

.divAccueil img {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  box-shadow: 0 0 15px 7px #2d2a32b3;
  max-height: 127vh;
  object-fit: cover;

  &.bg-portrait {
    //left: 27%;
    left: unset !important;
    margin-left: unset !important;
    width: unset !important;
    height: 85vh;
    position: relative;

    &.bg-portrait-event {
      max-height: 100vh;
      margin: auto;
      top: 0;
      bottom: 0;
    }
  }

  &:not(.bg-portrait) {
    width: 100%;
  }
}

#home img {
  object-fit: cover;
  object-position: 0px 65%;
}

#portrait,
#mariage {
  .parallax-text.bg-portrait {
    right: 0 !important;
  }

  img.bg-portrait {
    width: 70vh;
    margin-left: 15vh;
    left: 0%;
  }
}

#evenements,
#evenements .animationContainer {
  height: 110vh;
}

.divGalery {
  display: block;

  img {
    display: inline-block;
    position: relative;
    width: 100px !important;
    height: 100px;
    margin: 15px;
    cursor: pointer;
    transition: 0.5s ease;
    object-fit: cover;

    &:hover {
      opacity: 0.5;
    }
  }
}

.divLiensPresta {
  text-align: center;
  position: absolute;
  height: 15%;
  bottom: 15vh;
  left: 0;
  right: 0;
}
.divLiensPresta a {
  font-family: "Boulevard";
  font-size: 32pt;
  font-weight: bold;
  color: white !important;
  text-decoration: none;
}
.divAccueil {
  position: relative;
  width: 100%;
  height: 80vh;
  pointer-events: none;

  // &#home {
  // 	height: 50vw;
  // }

  &#mariage .parallax-text {
    top: 32%;
    width: 65vh;
    padding: 0 5vh;

    &:not(.bg-portrait) {
      color: white;
    }
  }

  .parallax-text.event-portrait {
    top: 15vh !important;
    width: 65vh !important;
    padding: 0 5vh !important;
  }
}
.divAccueil * {
  pointer-events: all;
}
.parallax-text {
  position: absolute;
  font-size: 1.7vw;
  z-index: 1;

  &:not(.bg-portrait) {
    color: white;
  }
}
.parallax-text h1 {
  font-size: 11vh;
}
.goto-gallery {
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px 20px;
  display: inline-block;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}
.goto-gallery.to-portrait {
  background: #824141;
  color: #fff4f4 !important;
  border: 4px solid #824141 !important;
}
.goto-gallery.to-evenement {
  background: #e5625d;
  color: #fff4f4 !important;
  border: 4px solid #e5625d !important;
}
.goto-gallery.to-mariage {
  background: #ca7647;
  color: #fff4f4 !important;
  border: 4px solid #ca7647 !important;
}
.goto-gallery.to-contact {
  background: #ffffff;
  color: #494949 !important;
  border: 4px solid #ffffff !important;
}
.goto-gallery:hover {
  color: #ffffff !important;
  background: #f6b93b;
  border-color: #f6b93b !important;
  transition: all 0.4s ease 0s;
}
.scroll-to-down {
  position: absolute;
  bottom: -20vh;
  right: 2%;
}
.scroll-to-down img {
  position: relative;
  box-shadow: none;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0.3;
}

.reseau-txt-hide {
  overflow: hidden;
  transition: max-height 0.25 ease-out;
  width: 60px;
  transition-property: width;

  label {
    width: 0;
  }
}

.reseau-txt-show {
  transition: max-height 0.25 ease-out;
  width: 300px;
  transition-duration: 1s;
  transition-property: width;
}

.slideright-enter-active,
.slideright-leave-active {
  transition-property: width;
  transition: 1s;
}

.slideright-enter,
.slideright-leave-to {
  width: 100px;
}

.slideright-enter-to,
.slideright-leave {
  width: 0;
}

img#photo-iris {
  width: 70vh;
  box-shadow: none;
  margin-left: 15vh;
  top: 1%;
}

#contact {
  background-color: #2d2a32;
  z-index: 10;
  height: auto;
  padding: 5vh 0;
  box-shadow: 0 0 15px 7px #2d2a32b3;
}
</style>
