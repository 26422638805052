const emailService = {
  sendConfirmationClient,
  sendToIris
} 

async function sendConfirmationClient(formulaire) {
  const formData  = new FormData();

  for(const name in formulaire) {
    formData.append(name, formulaire[name]);
  }

  return await fetch('http://192.168.1.94:8000/mail.php', {
    method: 'POST',
    body: formData
  })
}

async function sendToIris() {
  return await fetch('http://192.168.1.94:8000/mail.php')
}

export default emailService;

