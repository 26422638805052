<template>
	<div class="offre-container">
		<div class="offre">
			<b-tabs v-model="etape" no-key-nav no-nav-style>
				<b-tab>
					<h1>Envie d'un shooting ?</h1>
					<hr />
					
					<p>
						Offrez ou réservez votre séance pour <strong>1h30</strong> seul(e), en couple, en famille ou entre amis dans une ambiance détendue. <br />
						<!-- Je vous accompagne dans vos lieux préférés afin d'immortaliser discrètement les sourires et les meilleurs moments. -->
					</p>
					
					<h2>à partir de 200€*</h2>
					<p class="modalites">* Déplacements offerts dans la limite de 15km autour de Calais.</p>

					<div>
						<b-button variant="info" @click="etape++">Réserver ma date</b-button>
					</div>
				</b-tab>

				<b-tab>
					<h1>INFORMATIONS</h1>
					<hr />

					<b-form onsubmit="return false" @submit.prevent="etape = formValid ? etape + 1 : etape" ref="formAchat">
						<b-form-group label="Nom"><b-form-input v-model="formulaire.nom" required></b-form-input> </b-form-group>
						<b-form-group label="Prénom"><b-form-input v-model="formulaire.prenom" required></b-form-input></b-form-group>
						<b-form-group label="Téléphone">
							<b-input type="tel" name="phone_number" id="phone"
										:class="{ error: !formulaire.telValid }"
										v-model="formulaire.tel" />

							<p class="error-message" v-show="!formulaire.telValid">Numéro de téléphone invalide</p>
						</b-form-group>
						<b-form-group label="Adresse email">
							<b-input type="email" name="reply_to" id="email" required
										:class="{ error: !formulaire.emailValid }"
										v-model="formulaire.email" />

							<p class="error-message" v-show="!formulaire.emailValid">Adresse email invalide</p>
						</b-form-group>
						<b-form-group label="Nombre de participants"> <b-form-input type="number" v-model="formulaire.nbParticipants" min="1"></b-form-input></b-form-group>
						<b-form-group label="Date souhaitée (à titre indicatif)">
							<b-form-datepicker v-model="formulaire.dateSouhaitee"
								start-weekday="1"
								v-bind="localeFR"
								:date-format-options="{ 'weekday': 'long', 'month': 'long', 'year': 'numeric'}"
								:min="new Date()"
								locale="fr"
							>
							</b-form-datepicker>
							<p v-if="formulaire.dateSouhaitee" class="modalites">
								Attention ! La date que vous choisissez n'est pas définitive, elle ne servira que d'indication afin de répondre au mieux à votre demande. La date et l'horaire finaux seront fixés avec Iris.
							</p>
						</b-form-group>
						<b-form-group label="Commentaire"><b-form-textarea v-model="formulaire.commentaire" style="resize: none;"></b-form-textarea></b-form-group>

						<b-button variant="outline-secondary" @click="etape--" style="float: left">Retour</b-button>
						<b-button type="submit" variant="info"  style="float: right">Suivant</b-button>
					</b-form>
				</b-tab>
				
				<b-tab>
					<h2>PAIEMENT</h2>
					<hr />
					<div class="text-left">
						<label>Récapitulatif de votre demande</label>
						<b-table-simple bordered small fixed>
							<b-tr>
								<b-th>Nom</b-th>
								<b-td>{{ formulaire.nom }}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Prénom</b-th>
								<b-td>{{ formulaire.prenom }}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Téléphone</b-th>
								<b-td>{{ formulaire.tel }}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Adresse email</b-th>
								<b-td>{{ formulaire.email }}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Nombre de participants</b-th>
								<b-td>{{ formulaire.nbParticipants }}</b-td>
							</b-tr>
							<b-tr>
								<b-th>Date souhaitée</b-th>
								<b-td>{{ dateStr }}</b-td>
							</b-tr>
						</b-table-simple>
					</div>
					<hr />
						<b-form onsubmit="return false" @submit.prevent="validPaimentMainPropre()">
							<div>
								<b-form-group label="Paiement en ligne" v-slot="{ ariaDescribedby  }" stacked>
									<b-form-radio v-model="formulaire.paymentSelected" :aria-describedby="ariaDescribedby" name="choix-paiement" value="EnLigneCarte">Carte</b-form-radio>
									
									<b-overlay :show="paiementCBEnCours" variant="transparent" blur="5px">
										<form v-show="formulaire.paymentSelected === 'EnLigneCarte'" id="payment-form" class="m-3" onsubmit="return false" @submit.prevent="submitPayment()">
											<div id="payment-element">
											</div>
											<div id="error-message">
											</div>
											<div style="width: 100%; text-align: center">
													<b-button type="submit" class="m-3" variant="info" size="sm" style="font-size: 23px;" :disabled="paiementCBEnCours">Payer</b-button>
											</div>
										</form>
									</b-overlay>
									
									<b-form-radio v-model="formulaire.paymentSelected" :aria-describedby="ariaDescribedby" name="choix-paiement" value="EnLignePaypal">Paypal</b-form-radio>

									<div v-show="formulaire.paymentSelected === 'EnLignePaypal'" id="paypal-button-container">
										{{ formulaire.errorMessage }}
									</div>

								</b-form-group>
								<b-form-group label="Paiment en main propre" v-slot="{ ariaDescribedby  }">
									<b-form-radio v-model="formulaire.paymentSelected" :aria-describedby="ariaDescribedby" name="choix-paiement" value="EnMainPropre">Par chèque ou espèce, remise en main propre le jour du shooting</b-form-radio>
								</b-form-group>
							</div>
							<b-button variant="outline-secondary" @click="etape--" style="float: left">Retour</b-button>
							<b-button v-if="formulaire.paymentSelected === 'EnMainPropre'" type="submit" variant="info"  style="float: right" :disabled="paiementMPEnCours">Valider</b-button>
						</b-form>
				</b-tab>
							
				<b-tab>
					<b-icon icon="check2-circle"  style="width: 120px; height: 120px;" variant="success"></b-icon>
						<h3 v-if="formulaire.paymentSelected !== 'EnMainPropre'">Paiement réussi</h3>
					<p>
						Vous allez reçevoir un email de confirmation. <br />
						Je vous contacte dès que possible pour fixer le rendez-vous ensemble !
					</p>
					<p class="modalites">Si vous n'avez pas reçu d'email, vérifiez dans vos spam ;-)</p>
				</b-tab>
			</b-tabs>

		</div>
	</div>
</template>

<script>

import { loadScript } from "@paypal/paypal-js"
import paiementService from '@/services/payment.js'
import emailService from '@/services/mail.js'

import emailjs from 'emailjs-com';

// Regular expression from W3C HTML5.2 input specification:
// https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail
// eslint-disable-next-line
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// eslint-disable-next-line
var phoneRegExp = /^(0[1-68])(?:[ _.-]?(\d{2})){4}$/;

// eslint-disable-next-line no-undef
const stripe = Stripe( process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY)
export default {
	name: "offre",
	components: {
	},
	data() {
		this.publishableKey = process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
		return {
			etape: 0,
			paiementCBEnCours: false,
			paiementMPEnCours: false,
			formulaire: {
				nom: "",
				prenom: "",
				telValid: true,
				tel: '',
				emailValid: true,
				email: '',
				nbParticipants: 1,
				dateSouhaitee: null,
				commentaire: "",
				paymentSelected: 'EnLigneCarte',
				errorMessage: '',
				paymentStatus: 'Main propre, le jour du shooting'
			},
			localeFR: {
				labelPrevDecade: 'Semaine précédente',
				labelPrevYear: 'Année précédente',
				labelPrevMonth: 'Mois précédent',
				labelCurrentMonth: 'Mois actuel',
				labelNextMonth: 'Mois suivant',
				labelNextYear: 'Année suivante',
				labelNextDecade: 'Semaine suivante0',
				labelToday: 'Aujourd\'hui',
				labelSelected: 'Date sélectionnée',
				labelNoDateSelected: 'Aucune date sélectionnée',
				labelCalendar: 'Calendrier',
				labelNav: 'Navigation du calendrier',
				labelHelp: 'Utilisez les flèches du clavier pour naviguer'
			},
			stripe: {
				elements: null
			}
		}
	},
  watch: {
		// watching nested property
		"formulaire.email": function(value) {
			this.validate("email", value);
		},
		"formulaire.tel": function(value) {
				this.validate("phone", value);
		}
  },
	computed: {
		dateStr() {
			if (this.formulaire.dateSouhaitee)
				return new Date(this.formulaire.dateSouhaitee).toLocaleDateString()
			else
				return null
		},
		formValid() {
			return this.formulaire.emailValid && this.formulaire.telValid
		}
	},
	async created () {
		this.dateSouhaitee = new Date()
		this.dateSouhaitee.setDate(new Date().getDate() + 7)
	},
	async mounted() {

		// Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
		this.elements = stripe.elements({
			clientSecret: await paiementService.getSecretKey(),
		});

		// Create and mount the Payment Element
		const paymentElement = this.elements.create('payment');
		paymentElement.mount('#payment-element');

		const vm = this
				
		loadScript({ "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID, 'currency': 'EUR' })
		.then((paypal) => {
				paypal.Buttons({
					style: {
						shape: 'rect',
						color: 'gold',
						layout: 'horizontal',
						label: 'paypal',
						tagline: false
					},

					createOrder: async function(data, actions) {
						const offreEte = await paiementService.getOffreEte()
						return actions.order.create({
							purchase_units: [offreEte]
						});
					},

					onApprove: function(data, actions) {
						return actions.order.capture().then(async function() {
							vm.formulaire.paymentStatus = "Paypal -> OK"
							vm.etape++;
							await emailjs.send('ovh_mail', 'template_t9oqfji', vm.formulaire, 'user_TbPGSEZgwT5LLlGFcYUPy')
							await emailService.sendConfirmationClient(vm.formulaire)
						});
					},

					onError: function(err) {
						vm.formulaire.errorMessage = err;
					}
				}).render('#paypal-button-container');
		})
		.catch((err) => {
				// eslint-disable-next-line no-console
				console.error("failed to load the PayPal JS SDK script", err);
		});

	},
	methods: {
    // validate by type and value
    validate: function(type, value) {
      if (type === "email") {
        this.formulaire.emailValid = this.isEmail(value) ? true : false;
      }
      else if (type === "phone") {
        this.formulaire.telValid = this.isTelephone(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail: function(value) {
      return emailRegExp.test(value);
    },
    isTelephone: function(value) {
        return phoneRegExp.test(value);
    },
		async validPaimentMainPropre() {
			this.paiementMPEnCours = true
			await emailService.sendConfirmationClient(this.formulaire)
			this.etape++
			this.paiementMPEnCours = false
		},
		async submitPayment() {
			this.paiementCBEnCours = true
			const {error, paymentIntent} = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements: this.elements,
				confirmParams: {
					return_url: window.location.href,
				},
				redirect: 'if_required'
			});

			const messageContainer = document.querySelector('#error-message');

			if (error) {
				messageContainer.textContent = error.message;
			} else if (paymentIntent) {
				
				switch (paymentIntent.status) {
					case 'succeeded':
						this.formulaire.paymentStatus = "Par carte -> OK"
						await emailjs.send('ovh_mail', 'template_t9oqfji', this.formulaire, 'user_TbPGSEZgwT5LLlGFcYUPy')
						await emailService.sendConfirmationClient(this.formulaire)
						this.etape ++
						break;

					case 'processing':
						messageContainer.textContent = "Payment processing. We'll update you when payment is received.";
						break;

					case 'requires_payment_method':
						messageContainer.textContent = 'Payment failed. Please try another payment method.';
						// Redirect your user back to your payment page to attempt collecting
						// payment again
						break;

					default:
						messageContainer.textContent = 'Something went wrong.';
						break;
				}
			}
			this.paiementCBEnCours = false
		},
	},
}
</script>

<style lang="scss">
.offre-container {
	height: 100vh;
	width: 100vh;
	position: absolute;
	right: 0;
}

div.offre {
	z-index: 100;
	width: 50vh;
	background: #6badb385; // #ffe7d5a3; // #;ced7dda3
	color: white;
	position: absolute;
	right: 25%;
	top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
	padding: 20px;
	border-radius: 10px;

	p {
		font-size: 1vw;
		&.modalites {
			font-size: 0.6vw;
		}
	}
	h1 {
		font-size: 2vw;
	}
	h2 {
		font-size: 1.8vw;
	}
	button {
		font-size: 1.1vw;
	}

	form, .form-control {
		text-align: left;
		font-size: 0.8vw;

		legend {
			padding-bottom: 0;
		}
	}
	p.error-message, #error-message {
			margin-left: 7px;
			margin-top: 5px;
			margin-bottom: 0;
			color: #f05c5c;
			font-size: 0.8em;
	}
	.btn {
		font-size: 23px;
	}
}
</style>