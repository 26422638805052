import "babel-polyfill";

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueParallaxJs from 'vue-parallax-js'
import VueLoading from 'vuejs-loading-plugin'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// add vue-parallax-js to vue
const vw = Math.min(document.documentElement.clientWidth || 0, window.innerWidth || 0);
const vh = Math.min(document.documentElement.clientHeight || 0, window.innerHeight || 0);

let minSide = vw > vh ? vh : vw;
let maxSide = vw > vh ? vw : vh;

var isParallaxActivated = (maxSide >= 992 && maxSide >= minSide * (30/17));

const options = {
  minWidth : isParallaxActivated ? 0 : Infinity,
}

Vue.use(VueParallaxJs, options)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueLoading)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
