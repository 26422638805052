const paiementService = {
  getSecretKey,
  getOffreEte
} 

const apiUrl = process.env.VUE_APP_API_URL

async function getSecretKey() {
  const response = await fetch(apiUrl + 'secret.php', { method: 'GET' })
  const { client_secret: clientSecret } = await response.json()
  return clientSecret;
}

async function getOffreEte() {
  const response = await fetch(apiUrl + '/paypalOrder.php', { method: 'GET' })
  return response.json()
}

export default paiementService;

